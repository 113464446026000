import store from "@/store"

export default {
    methods: {
        formatTimeDelta(d) {
            let delta = d / 1000
            let ld = store.state.locale == 'en' ? " days " : " Tage "
            let lh = store.state.locale == 'en' ? " hours " : " Stunden "
            let lm = store.state.locale == 'en' ? " minutes " : " Minuten "
            let ls = store.state.locale == 'en' ? " seconds " : " Sekunden "

            let days = Math.floor(delta / 86400)
            let hours = Math.floor((delta % 86400) / 3600)
            let minutes = Math.floor(((delta % 86400) % 3600) / 60)
            let seconds = Math.floor(((delta % 86400) % 3600) % 60)
            let string = ''
            if(days > 0) string += days + ld
            if(hours > 0) string += hours + lh
            if(minutes > 0) string += minutes + lm
            if(seconds > 0) string += seconds + ls
            return string
        }
    }
}