<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      {{ name }}
      <v-spacer />
      <v-btn
        @click="resetPassword"
        :disabled="!!resetPasswordTimer || loading"
        class="mr-2"
        text
        >{{ $t("Reset Password") }}</v-btn
      ><timer
        @time-up="resetPasswordTimer = 0"
        v-if="!!resetPasswordTimer"
        :value="resetPasswordTimer"
      />
      <wiki slug="net-backup" />
    </v-toolbar>
    <v-progress-linear :active="loading" indeterminate />
    <v-card-text>
      <v-card outlined class="mt-1">
        <validation-observer ref="obs" v-slot="{ invalid }">
          <v-card-text v-if="loadingNetbackup">
            <v-skeleton-loader
              type="list-item-avatar, article, actions"
            ></v-skeleton-loader>
          </v-card-text>
          <v-card-text v-else>
            <v-row>
              <v-col>
                <v-progress-circular
                  :rotate="90"
                  :size="150"
                  :width="20"
                  :value="
                    (netbackup.user.total_statistics.TotalUploadCompressedSize /
                      netbackup.user.quota_list[0].Quota) *
                    100
                  "
                  color="teal"
                >
                  <div class="text-center">
                    <div>
                      {{
                        formatBytesBaseTwo(
                          netbackup.user.total_statistics
                            .TotalUploadCompressedSize
                        )
                      }}/
                    </div>
                    <div>
                      {{
                        formatBytesBaseTwo(netbackup.user.quota_list[0].Quota)
                      }}
                    </div>
                  </div>
                </v-progress-circular>
              </v-col>
              <v-col>
                <h3>{{ $t("Email List") }}</h3>

                <template v-for="contact in netbackup.user.contact_list">
                  <validation-provider
                    :key="contact.Id"
                    :vid="'email_' + contact.Id"
                    name="email"
                    v-slot="{ errors, valid, dirty }"
                    rules="required|email"
                  >
                    <v-text-field
                      append-outer-icon="mdi-trash-can"
                      @click:append-outer="
                        netbackup.user.contact_list =
                          netbackup.user.contact_list.filter(
                            (el) => el !== contact
                          )
                      "
                      v-model="contact.Email"
                      :error-messages="errors"
                      :success="dirty && valid"
                    >
                    </v-text-field>
                  </validation-provider>
                </template>
                <v-btn
                  icon
                  :disabled="netbackup.user.contact_list.length <= 1"
                  @click="netbackup.user.contact_list.pop()"
                  ><v-icon>mdi-minus</v-icon></v-btn
                >
                <v-btn
                  icon
                  @click="netbackup.user.contact_list.push({ Email: '' })"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="loadNetbackup()" :disabled="loadingNetbackup">
              {{ $t("reset") }}
            </v-btn>
            <v-btn
              color="primary"
              :disabled="loadingNetbackup || invalid"
              @click="changeContacts"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </validation-observer>
      </v-card>

      <v-card outlined class="mt-3"> 
        <v-alert type="warning" v-if="!!worker">{{ $t("Running Workers") }}</v-alert>
        <v-card-text class="d-flex flex-wrap pa-2" v-if="loadingModules">
          <v-card
            width="260"
            class="d-flex flex-column ma-2"
            v-for="n in 13"
            :key="n"
            outlined
          >
            <v-skeleton-loader type="article, actions" />
          </v-card>
        </v-card-text>

        <template v-else>
          <!-- base -->
          <v-card-title>
            <v-icon class="mx-1">mdi-format-list-checks</v-icon> {{ $t("Base Modules") }}
          </v-card-title>
          <v-card-text class="d-flex flex-wrap pa-2">
            <net-backup-module
              v-bind:module="module"
              v-for="module in modules.filter((el) => el.tags.includes('base'))"
              :key="module.product_type_id"
            />
          </v-card-text>
          <!-- modules -->
          <v-card-text class="d-flex flex-wrap pa-2">
            <net-backup-module
              v-bind:module="module"
              v-for="module in modules.filter(
                (el) =>
                  !(
                    el.tags.includes('base') ||
                    el.tags.includes('vmware') ||
                    el.tags.includes('hyperv')
                  )
              )"
              :key="module.product_type_id"
            />
          </v-card-text>
          <!-- hyperv -->
          <v-card-title>
            <v-icon class="mx-1">mdi-server</v-icon> {{ "Hyper V" }}
          </v-card-title>
          <v-card-text class="d-flex flex-wrap pa-2">
            <net-backup-module
              v-bind:module="modules.find((el) =>
                el.tags.includes('hyperv') && el.tags.includes('vm')
              )"
              :disabled="modules.find((el) =>
                el.tags.includes('hyperv') && el.tags.includes('socket')
              ).active"
            />
            <net-backup-module
              v-bind:module="modules.find((el) =>
                el.tags.includes('hyperv') && el.tags.includes('socket')
              )"
              :disabled="modules.find((el) =>
                el.tags.includes('hyperv') && el.tags.includes('vm')
              ).active"
            />
          </v-card-text>
          <v-card-title>
            <v-icon class="mx-1">mdi-server</v-icon> {{ "VM Ware" }}
          </v-card-title>
          <!-- vmware -->
          <v-card-text class="d-flex flex-wrap pa-2">
            <net-backup-module
              v-bind:module="modules.find((el) =>
                el.tags.includes('vmware') && el.tags.includes('vm')
              )"
              :disabled="modules.find((el) =>
                el.tags.includes('vmware') && el.tags.includes('socket')
              ).active"
            />
            <net-backup-module
              v-bind:module="modules.find((el) =>
                el.tags.includes('vmware') && el.tags.includes('socket')
              )"
              :disabled="modules.find((el) =>
                el.tags.includes('vmware') && el.tags.includes('vm')
              ).active"
            />
          </v-card-text>
        </template>

        <v-dialog v-model="previewDialog" max-width="750">
          <v-card>
            <v-card-title>
              {{ $t("order preview") }}
            </v-card-title>
            <v-card-text>
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("Module") }}
                    </th>
                    <th class="text-left" v-if="!$store.getters.isSalesPartner">
                      {{ $t("cost") }}
                    </th>
                    <th>
                      {{ $t("change") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="module in order" :key="module.product_type_id">
                    <td>
                      {{ module.product_name }}
                    </td>
                    <td class="monospace text-no-wrap" v-if="!$store.getters.isSalesPartner">
                      {{ formatMoney(module.price) }}
                    </td>
                    <td>
                      {{ getChange(module) }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="previewDialog = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="primary"
                @click="
                  placeOrder();
                  previewDialog = false;
                "
              >
                {{ $t("order") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="loadModules(); loadProduct()" :disabled="loadingModules">
            {{ $t("reset") }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="loadingModules || order.length < 1 || loadingProduct || !!worker"
            @click="previewDialog = true"
          >
            {{ $t("order preview") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import formatMoney from "@/utils/mixins/formatMoney";
import formatBytesBaseTwo from "@/utils/mixins/formatBytesBaseTwo";
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";
import Timer from "@/components/basics/Timer";
import NetBackupModule from "@/components/services/NetBackupModule";
import ProductTools from "@/utils/mixins/productTools";
import checkWorkers from "@/utils/mixins/checkWorkers";
import showErrors from "@/utils/mixins/showErrors";


export default {
  name: "NetBackupDetails",
  mixins: [formatMoney, formatBytesBaseTwo, isMobile, ProductTools, checkWorkers, showErrors],
  components: {
    Wiki,
    Timer,
    NetBackupModule,
  },
  props: {
    productId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      resetPasswordTimer: 0,

      loadingProduct: false,
      loadingModules: false,
      loadingNetbackup: false,
      loadingReset: false,

      product: null,
      base_modules: [],
      modules: [],
      exclusive_modules: [],
      netbackup: null,

      storage: null,
      activateList: [],
      deactivateList: [],
      worker: null,
      previewDialog: false,

      name: "",
    };
  },
  watch: {
    productId: {
      immediate: true,
      handler() {
        this.loadProduct();
        this.loadNetbackup();
        this.loadModules();
      },
    },
  },
  computed: {
    loading() {
      return (
        this.loadingProduct ||
        this.loadingModules ||
        this.loadingReset ||
        this.loadingNetbackup
      );
    },
    order() {
      let or = [];
      this.modules.forEach((option) => {
        if (
          option.active != option.default_active ||
          (option.active && option.number != option.default_number)
        ) {
          or.push({
            active: option.active,
            default_active: option.default_active,
            product_number: option.product_number,
            product_name: option.product_type_name,
            product_type_id: option.product_type_id,
            allow_quantity: option.allow_quantity,
            default_number: option.default_number,
            number: option.number,
            price: this.getPrice(option),
          });
        }
      });
      return or;
    },
  },
  methods: {
    loadModules() {
      this.loadingModules = true;
      let that = this;
      this.$http
        .get("services/net-backup/" + this.productId)
        .then((response) => {
          that.modules = response.data;
        })
        .catch((error) => {
          that.showErrors(error.data);
        })
        .finally(() => {
          that.loadingModules = false;
        });
    },
    loadProduct() {
      this.loadingProduct = true;
      let that = this;
      this.$http
        .get("products/" + this.productId)
        .then((response) => {
          that.product = response.data;
          that.worker = null;
          that.checkWorkers(
            response.data.workers,
            that.checkWorkerCallback,
            "runningWorkers",
            "loadingWorker"
          );
        })
        .catch((error) => {
          that.showErrors(error.data);
        })
        .finally(() => {
          that.loadingProduct = false;
        });
    },
    loadNetbackup() {
      this.loadingNetbackup = true;
      let that = this;
      this.$http
        .get("services/net-backup/" + this.productId + "/settings")
        .then((response) => {
          that.netbackup = response.data;
          that.name = that.netbackup.user.name;
        })
        .catch((error) => {
          that.showErrors(error.data);
        })
        .finally(() => {
          that.loadingNetbackup = false;
        });
    },
    placeOrder() {
      this.loadingModules = true;
      let that = this;
      this.$http
        .patch("services/net-backup/" + this.productId, this.order)
        .then((response) => {
          that.worker = response.data;
          that.$snotify.success(
            that.$i18n.t(
              "Your {worker} {subject} has been successfully commited.",
              {
                worker: response.data.worker_name,
                subject: response.data.subject,
              }
            )
          );
        })
        .catch((error) => {
          that.showErrors(error.data);
        })
        .finally(() => {
          that.loadingModules = false;
        });
    },
    resetPassword() {
      this.loadingReset = true;
      let that = this;
      this.$http
        .post("services/net-backup/" + this.productId + "/reset_password", {})
        .then((response) => {
          that.$snotify.success(that.$i18n.t("Password reset email sent"));
        })
        .catch((error) => {
          that.showErrors(error.data);
        })
        .finally(() => {
          that.loadingReset = false;
          that.resetPasswordTimer = 60000;
        });
    },
    changeContacts() {
      this.loadingProduct = true;
      let contactList = this.netbackup.user.contact_list.filter(
        (el) => el.Email !== ""
      );
      let that = this;
      this.$http
        .post(
          "services/net-backup/" + this.productId + "/update_contacts",
          contactList
        )
        .then((response) => {
          that.$snotify.success(that.$i18n.t("Contacts updated"));
          that.loadProduct();
        })
        .catch((error) => {
          that.showErrors(error.data);
        })
        .finally(() => {
          that.loadingProduct = false;
        });
    },
    getChange(option) {
      let text = "";
      if (option.active && !option.default_active) {
        text = text + "Activated ";
      }
      if (!option.active && option.default_active) {
        text = text + "Deactivated ";
      }
      if (option.active && option.number != option.default_number) {
        text = text + " " + option.default_number + " => " + option.number;
      }
      return text;
    },
    getPrice(price) {
      if(this.$store.getters.isEndCustomerr) {
        return this.formatMoney(this.getProductPriceIncVat(price));
      } else if(this.$store.getters.isWho) {
        return this.formatMoney(price);
      } else {
        return null
      }
    },
    checkWorkerCallback(worker) {
      console.log(worker);
      let matched = [
        "workerticketnetbackup",
        "workerticketproductblock",
        "workerticketproducttermination",
      ].includes(worker.worker_code);
      if(matched) {
        this.worker = worker;
      } else {
        this.worker = null;
      }
      return !matched;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "Reset Password": "Reset Password",
    "Module": "Module",
    "Modules": "Modules",
    "order preview": "Order Preview",
    "cost": "Cost",
    "change": "Change",
    "order": "Order",
    "reset": "Reset",
    "Email List": "Email List",
    "inactive": "inactive",
    "active": "active",
    "Base Modules": "Base Modules",
    "Contacts updated": "Contacts updated",
    "Password reset email sent": "Password reset email sent",
    "Your {worker} {subject} has been successfully commited.": "Your {worker} {subject} has been successfully commited."
  },
  "de": {
    "Reset Password": "Passwort zurücksetzen",
    "Module": "Modul",
    "Modules": "Module",
    "order preview": "Bestellvorschau",
    "cost": "Kosten",
    "change": "Änderung",
    "order": "Bestellen",
    "reset": "Zurücksetzen",
    "Email List": "Email-Liste",
    "inactive": "inaktiv",
    "active": "aktiv",
    "Base Modules": "Basis Module",
    "Contacts updated": "Kontakte aktualisiert",
    "Password reset email sent": "Passwort-Zurücksetzen E-Mail gesendet",
    "Your {worker} {subject} has been successfully commited.": "Ihre {worker} {subject} wurde erfolgreich übermittelt."
  }
}
</i18n>