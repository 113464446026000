<template>
<v-card
    class="d-flex flex-column mr-2 mb-2"
    width="260"
    min-height="100"
    outlined
>
    <v-overlay
    v-if="disabled"
    absolute
    opacity="0.2"
    >
    </v-overlay>
    <v-card-text>
    <h4>
      {{ module.product_type_name }}
    </h4>
    </v-card-text>
    <v-spacer class="mb-auto"></v-spacer>
    <v-card-actions>
    <v-chip small :color="module.default_active ? 'success' : ''">
      {{ module.default_active ? $t("active") : $t("inactive") }}
    </v-chip>
    <v-spacer></v-spacer>
    {{ price }}
    </v-card-actions>
    <v-card-actions>
    <v-switch
      v-model="module.active"
      :label="module.active ? $t('active') : $t('inactive')"
      color="success"
    ></v-switch>
    <v-spacer></v-spacer>
    <template v-if="module.allow_quantity && module.active">
      <h4 class="mr-3">{{ module.number }}</h4>
      <v-icon
      @click="module.number > 1 ? module.number-- : null"
      :disabled="module.number <= 1"
      class="ml-1"
      >
      mdi-minus-circle
      </v-icon>
      <v-icon @click="module.number++">
      mdi-plus-circle
      </v-icon>
    </template>
    </v-card-actions>
</v-card>
  
</template>

<script>
import formatMoney from "@/utils/mixins/formatMoney";
import ProductTools from "@/utils/mixins/productTools";

export default {
  name: "NetBackupModule",
  mixins: [formatMoney, ProductTools],
  props: {
    module: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    price() {
      if(this.$store.getters.isEndCustomerr) {
        return this.formatMoney(this.getProductPriceIncVat(this.module));
      } else if(this.$store.getters.isWho) {
        return this.formatMoney(this.module.price);
      } else {
        return null
      }
      
    },
  },
  watch: {
    module: {
      handler() {
        this.$emit("module", this.module);
      },
      deep: true
    }
  },
};
</script>

<i18n>
{
  "en": {
    "inactive": "inactive",
    "active": "active"
  },
  "de": {
    "inactive": "inaktiv",
    "active": "aktiv"
  }
}
</i18n>