var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_vm._v(" "+_vm._s(_vm.name)+" "),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":!!_vm.resetPasswordTimer || _vm.loading,"text":""},on:{"click":_vm.resetPassword}},[_vm._v(_vm._s(_vm.$t("Reset Password")))]),(!!_vm.resetPasswordTimer)?_c('timer',{attrs:{"value":_vm.resetPasswordTimer},on:{"time-up":function($event){_vm.resetPasswordTimer = 0}}}):_vm._e(),_c('wiki',{attrs:{"slug":"net-backup"}})],1),_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":""}}),_c('v-card-text',[_c('v-card',{staticClass:"mt-1",attrs:{"outlined":""}},[_c('validation-observer',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [(_vm.loadingNetbackup)?_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar, article, actions"}})],1):_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-progress-circular',{attrs:{"rotate":90,"size":150,"width":20,"value":(_vm.netbackup.user.total_statistics.TotalUploadCompressedSize /
                    _vm.netbackup.user.quota_list[0].Quota) *
                  100,"color":"teal"}},[_c('div',{staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatBytesBaseTwo( _vm.netbackup.user.total_statistics .TotalUploadCompressedSize ))+"/ ")]),_c('div',[_vm._v(" "+_vm._s(_vm.formatBytesBaseTwo(_vm.netbackup.user.quota_list[0].Quota))+" ")])])])],1),_c('v-col',[_c('h3',[_vm._v(_vm._s(_vm.$t("Email List")))]),_vm._l((_vm.netbackup.user.contact_list),function(contact){return [_c('validation-provider',{key:contact.Id,attrs:{"vid":'email_' + contact.Id,"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid, dirty }){return [_c('v-text-field',{attrs:{"append-outer-icon":"mdi-trash-can","error-messages":errors,"success":dirty && valid},on:{"click:append-outer":function($event){_vm.netbackup.user.contact_list =
                        _vm.netbackup.user.contact_list.filter(
                          (el) => el !== contact
                        )}},model:{value:(contact.Email),callback:function ($$v) {_vm.$set(contact, "Email", $$v)},expression:"contact.Email"}})]}}],null,true)})]}),_c('v-btn',{attrs:{"icon":"","disabled":_vm.netbackup.user.contact_list.length <= 1},on:{"click":function($event){return _vm.netbackup.user.contact_list.pop()}}},[_c('v-icon',[_vm._v("mdi-minus")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.netbackup.user.contact_list.push({ Email: '' })}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],2)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.loadingNetbackup},on:{"click":function($event){return _vm.loadNetbackup()}}},[_vm._v(" "+_vm._s(_vm.$t("reset"))+" ")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loadingNetbackup || invalid},on:{"click":_vm.changeContacts}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])],1)]}}])})],1),_c('v-card',{staticClass:"mt-3",attrs:{"outlined":""}},[(!!_vm.worker)?_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(_vm._s(_vm.$t("Running Workers")))]):_vm._e(),(_vm.loadingModules)?_c('v-card-text',{staticClass:"d-flex flex-wrap pa-2"},_vm._l((13),function(n){return _c('v-card',{key:n,staticClass:"d-flex flex-column ma-2",attrs:{"width":"260","outlined":""}},[_c('v-skeleton-loader',{attrs:{"type":"article, actions"}})],1)}),1):[_c('v-card-title',[_c('v-icon',{staticClass:"mx-1"},[_vm._v("mdi-format-list-checks")]),_vm._v(" "+_vm._s(_vm.$t("Base Modules"))+" ")],1),_c('v-card-text',{staticClass:"d-flex flex-wrap pa-2"},_vm._l((_vm.modules.filter((el) => el.tags.includes('base'))),function(module){return _c('net-backup-module',{key:module.product_type_id,attrs:{"module":module}})}),1),_c('v-card-text',{staticClass:"d-flex flex-wrap pa-2"},_vm._l((_vm.modules.filter(
              (el) =>
                !(
                  el.tags.includes('base') ||
                  el.tags.includes('vmware') ||
                  el.tags.includes('hyperv')
                )
            )),function(module){return _c('net-backup-module',{key:module.product_type_id,attrs:{"module":module}})}),1),_c('v-card-title',[_c('v-icon',{staticClass:"mx-1"},[_vm._v("mdi-server")]),_vm._v(" "+_vm._s("Hyper V")+" ")],1),_c('v-card-text',{staticClass:"d-flex flex-wrap pa-2"},[_c('net-backup-module',{attrs:{"module":_vm.modules.find((el) =>
              el.tags.includes('hyperv') && el.tags.includes('vm')
            ),"disabled":_vm.modules.find((el) =>
              el.tags.includes('hyperv') && el.tags.includes('socket')
            ).active}}),_c('net-backup-module',{attrs:{"module":_vm.modules.find((el) =>
              el.tags.includes('hyperv') && el.tags.includes('socket')
            ),"disabled":_vm.modules.find((el) =>
              el.tags.includes('hyperv') && el.tags.includes('vm')
            ).active}})],1),_c('v-card-title',[_c('v-icon',{staticClass:"mx-1"},[_vm._v("mdi-server")]),_vm._v(" "+_vm._s("VM Ware")+" ")],1),_c('v-card-text',{staticClass:"d-flex flex-wrap pa-2"},[_c('net-backup-module',{attrs:{"module":_vm.modules.find((el) =>
              el.tags.includes('vmware') && el.tags.includes('vm')
            ),"disabled":_vm.modules.find((el) =>
              el.tags.includes('vmware') && el.tags.includes('socket')
            ).active}}),_c('net-backup-module',{attrs:{"module":_vm.modules.find((el) =>
              el.tags.includes('vmware') && el.tags.includes('socket')
            ),"disabled":_vm.modules.find((el) =>
              el.tags.includes('vmware') && el.tags.includes('vm')
            ).active}})],1)],_c('v-dialog',{attrs:{"max-width":"750"},model:{value:(_vm.previewDialog),callback:function ($$v) {_vm.previewDialog=$$v},expression:"previewDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("order preview"))+" ")]),_c('v-card-text',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t("Module"))+" ")]),(!_vm.$store.getters.isSalesPartner)?_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t("cost"))+" ")]):_vm._e(),_c('th',[_vm._v(" "+_vm._s(_vm.$t("change"))+" ")])])]),_c('tbody',_vm._l((_vm.order),function(module){return _c('tr',{key:module.product_type_id},[_c('td',[_vm._v(" "+_vm._s(module.product_name)+" ")]),(!_vm.$store.getters.isSalesPartner)?_c('td',{staticClass:"monospace text-no-wrap"},[_vm._v(" "+_vm._s(_vm.formatMoney(module.price))+" ")]):_vm._e(),_c('td',[_vm._v(" "+_vm._s(_vm.getChange(module))+" ")])])}),0)])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.previewDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.placeOrder();
                _vm.previewDialog = false;}}},[_vm._v(" "+_vm._s(_vm.$t("order"))+" ")])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.loadingModules},on:{"click":function($event){_vm.loadModules(); _vm.loadProduct()}}},[_vm._v(" "+_vm._s(_vm.$t("reset"))+" ")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loadingModules || _vm.order.length < 1 || _vm.loadingProduct || !!_vm.worker},on:{"click":function($event){_vm.previewDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("order preview"))+" ")])],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }