export default {
  methods: {
    formatBytesBaseTwo: function (bytes, decimals = 2) {
      if (!+bytes) return '0 B'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
  }
};