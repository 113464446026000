<template>
    <v-chip :class="style">{{ formatTimeDelta(timeDelta) }}</v-chip>
</template>

<script>
import formatTimeDelta from "@/utils/mixins/formatTimeDelta";

export default {
  name: "Timer",
  mixins: [formatTimeDelta],
  props: {
    value: {
      type: Number,
      default: null,
    },
    id: {
      type: String,
    },
    style: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      timeDelta: 0,
      interval: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (this.interval) {
          clearInterval(this.interval);
        }
        this.timeDelta = value;
        this.interval = setInterval(() => {
          this.timeDelta = this.timeDelta - 1000;
            if (this.timeDelta <= 0) {
                clearInterval(this.interval);
                this.$emit("time-up");
            }
        }, 1000);
      },
    },
  },
}
</script>
